<template>
    <div>
        <div class="modal_header">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple">
                        <div class="title_h">
                            Новая {{ $t("message.resourcesText") }}
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                            <el-button type="success">
                                {{$t('message.save_and_close')}}
                            </el-button>
                            <el-button> {{$t('message.close')}} </el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="molad-content">
            <el-form
                ref="form"
                :model="form"
                size="small"
                class="inputBgNone bg_oq p20"
            >
                <el-row :gutter="20">
                    <el-col :sm="12">
                        <el-form-item label="Наименование ">
                            <el-input
                                v-model="form.name2"
                                placeholder="Наименование"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- end col -->

                    <el-col :sm="12">
                        <el-form-item label="Короткое название">
                            <el-input
                                v-model="form.name2"
                                placeholder="Короткое название"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- end col -->

                    <!-- end col -->
                </el-row>

                <!-- end el-row -->
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {},
            items: [
                {
                    name3: "",
                    name2: "",
                },
            ],
        };
    },
};
</script>